import { useState, useEffect } from 'react';
import { useStore } from '/src/store.js';
import { useTranslate } from '@tolgee/react';
import { LoadingOutlined } from '@ant-design/icons';
import { Avatar, Spin } from 'antd';
import agents_manager from '/src/agents_manager.js';
import './ChatSidebar.css';

const SideBar = ({ setSelectedAgent, loading }) => {
  const { t } = useTranslate();
  const [selectedIndex] = useState(null);
  const [agentsAvailable, setAgentsAvailable] = useState([]);
  const agents = useStore('agents');

  useEffect(() => {
    const agents_available = agents_manager.get_agents_available();
    setAgentsAvailable(agents_available);
  }, [agents]);

  return (
    <div className='chat-modal-left-container'>
      <div className='chat-modal-header'>
        <div className='chat-modal-header-title'>
          {t('chat_modal_title', 'Assistant Chats')}
        </div>
      </div>
      <div className='chat-modal-sidebar-container'>
        {agentsAvailable.map((agent_hash_id, index) => (
          <div
            className={
              'chat-modal-sidebar-item ' +
              (selectedIndex === index
                ? 'chat-modal-sidebar-item-selected'
                : '') +
              (selectedIndex - 1 === index ||
              (selectedIndex === -1 && index === agentsAvailable.length - 1)
                ? 'chat-modal-sidebar-item-selected-below'
                : '')
            }
            onClick={() => setSelectedAgent(agent_hash_id)}
            key={index}
          >
            <div className='chat-modal-sidebar-item-avatar'>
              {loading ? (
                <Spin
                  indicator={<LoadingOutlined spin style={{ fontSize: 36 }} />}
                />
              ) : (
                <Avatar size={44} src={agents[agent_hash_id].icon}></Avatar>
              )}
            </div>
            <div className='chat-modal-sidebar-item-info'>
              <div className='chat-modal-sidebar-item-title'>
                {agents[agent_hash_id].agent_name.name}
              </div>
              {agents[agent_hash_id].state === 'responding' && (
                <div className='chat-modal-sidebar-item-subtitle chat-modal-sidebar-item-typing'>
                  {agents[agent_hash_id].agent_name.name + ' is typing...'}
                </div>
              )}
              {agents[agent_hash_id].state !== 'responding' && (
                <div className='chat-modal-sidebar-item-subtitle'>
                  {agents[agent_hash_id].agent_name.complement}
                </div>
              )}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default SideBar;
