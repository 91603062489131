import { useEffect, useState } from 'react';
import { useStore } from '/src/store.js';
import event_bus from '/src/event_bus.js';
import './Header.css';
import { Input, Space, Tooltip } from 'antd';
import { useTranslate } from '@tolgee/react';
import LanguagePicker from 'design-system/src/ui/LanguagePicker/LanguagePicker.jsx';
import agents_manager from '/src/agents_manager.js';

const { Search } = Input;
const RENDER_ON = [
  /^.*\/dashboard.*$/,
  /^.*\/users.*$/,
  /^.*\/projects.*$/,
  /^.*\/home.*$/,
  /^.*\/classification-page.*$/,
  /^.*\/agents.*$/,
  /^.*\/agent.*$/,
  /^.*\/connector.*$/,
  /^.*\/connectors.*$/,
  /^.*\/tasks.*$/,
];

function Header() {
  const [shouldRender, setShouldRender] = useState(false);
  const [showTranslationIcon, setShowTranslationIcon] = useState(false);
  const { t } = useTranslate();
  const agents = useStore('agents');
  const [isAgentChatAvailable, setIsAgentChatAvailable] = useState(false);

  const check_available_agents = () => {
    const agents_available = agents_manager.get_agents_available();
    if (agents_available.length) {
      setIsAgentChatAvailable(true);
    }
  };

  useEffect(check_available_agents, [agents]);
  useEffect(check_available_agents, []);
  useEffect(() => {
    event_bus.on('loadingTranslation', () => {
      setShowTranslationIcon(true);
    });
    event_bus.on('finishedTranslation', () => {
      setShowTranslationIcon(false);
    });
  });

  const tooltipTranslationContent = (
    <div className='tooltip-translation-content-main'>
      <div className='tooltip-translation-content-text title'>Translation in progress... </div>
      <div className='tooltip-translation-content-text'>We are live translating the content of this page for you.</div>
    </div>
  );

  const location = useStore('location');
  useEffect(() => {
    // Check if current location matches any of the regexes in RENDER_ON
    const shouldRenderOnNewPage = RENDER_ON.some((regex) =>
      regex.test(location.pathname)
    );
    setShouldRender(shouldRenderOnNewPage);
  }, [location]);

  // const onSearch = (value, _e, info) => console.log(info?.source, value);

  return (
    <>
      {shouldRender && (
        <div className="header-container">
          <div className="header-icons-container">
            {isAgentChatAvailable && (
              <span
                onClick={agents_manager.open_chat_modal}
                className="material-icons header-icon"
              >
                smart_toy
              </span>
            )}
            {showTranslationIcon && (
              <Tooltip title={tooltipTranslationContent} arrow={false}>
                <span className="material-icons language-manager-notification-icon">translate</span>
              </Tooltip>
            )}
          </div>
          <div>
            <Space direction="vertical">
              <Search
                className="search-bar"
                placeholder={t('header_search_placeholder', 'Search...')}
                allowClear
                size="large"
                // onSearch={onSearch}
                color="rgba(247, 183, 66, 0.5)"
                // >>> To fix
                // Remove inline CSS
                style={{
                  width: 400,
                  backgroundColor: 'rgba(247, 183, 66, 0.5)',
                }}
              />
            </Space>
          </div>
          <div
            // >>> To fix
            // Remove inline CSS
            style={{
              marginRight: '10px',
            }}
          >
            <LanguagePicker />
          </div>
        </div>
      )}
    </>
  );
}

export default Header;
