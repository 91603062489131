import { useState, useEffect, useRef } from 'react';
import { useTranslate } from '@tolgee/react';
import { Input } from 'antd';
import AvatarIcon from './AvatarIcons';
import Message from './Message';
import { HandleCaptureDiv } from '/src/utils/htmlToImg';
import { useStore } from '/src/store.js';
import agents_manager from '/src/agents_manager.js';
import event_bus from '/src/event_bus.js';
import Lottie from 'lottie-react';
import typingAnimation from './assets/TypingAnimation.json';
import './Chat.css';

const Chat = ({ selectedAgent, setOpen, setOpenAbout }) => {
  const agents = useStore('agents');
  const { t } = useTranslate();
  const [inputValue, setInputValue] = useState('');
  const [inputDisabled, setInputDisabled] = useState(false);
  const [hoveredMessage, setHoveredMessage] = useState(null);
  const messageRefs = useRef([]);
  const chatRef = useRef(null);
  const isGroupChat = selectedAgent?.agent_id === 'group_agent';

  // Scroll to the end of the conversation when a new message arrives
  useEffect(() => {
    event_bus.on('agent_message_update', (agent_hash_id) => {
      if (agent_hash_id === selectedAgent) {
        setTimeout(() => {
          const scroll_to_element = document.getElementById(
            'chat-modal-scroll-to'
          );
          if (scroll_to_element) {
            scroll_to_element.scrollTo(0, scroll_to_element.scrollHeight);
          }
        }, 500);
      }
    });
  }, []);

  const onSend = () => {
    if (inputValue) {
      agents_manager.send_message(selectedAgent, inputValue);
      setInputValue('');
    }
  };

  const SendMessage = (disabled) => {
    return (
      <div
        style={disabled ? { cursor: 'inherit' } : { cursor: 'pointer' }}
        className='material-icons chat-modal-chat-input-icon'
        onClick={onSend}
      >
        send
      </div>
    );
  };

  const onKeyDown = (e) => {
    if (e.key === 'Enter') {
      onSend();
    }
  };

  useEffect(() => {
    if (agents[selectedAgent].state !== 'idle') {
      setInputDisabled(true);
    } else {
      setInputDisabled(false);
    }
  }, [agents]);

  return (
    <div className='chat-modal-right-container'>
      <div className='chat-modal-header'>
        <div className='chat-modal-header-agent-name-container'>
          <div
            className='chat-modal-header-agent-name-info'
            onClick={() => setOpenAbout(true)}
          >
            <AvatarIcon
              isGroupChat={false}
              agents={[]}
              selectedAgent={agents[selectedAgent]}
              size={agents.length > 2 ? 24 : 38}
            />
            <div className='chat-modal-header-agent-name'>
              <div className='chat-modal-header-agent-name-title'>
                {agents[selectedAgent].agent_name.name}
              </div>
              <div className='chat-modal-header-agent-name-subtitle'>
                {agents[selectedAgent].agent_name.complement}
              </div>
            </div>
          </div>
          <div
            // >>> To fix
            // Should be a class
            style={{
              display: 'flex',
              justifyContent: 'right',
            }}
          >
            <div
              className='material-icons chat-modal-header-title-icon'
              key='back'
              onClick={() => agents_manager.clear_agent(selectedAgent)}
            >
              delete
            </div>
            <div
              // >>> To fix
              onClick={() =>
                HandleCaptureDiv({
                  captureDiv: chatRef.current,
                  isModal: true,
                })
              }
              className='material-icons chat-modal-header-title-icon'
            >
              download
            </div>
            <div
              className='material-icons chat-modal-header-title-icon'
              key='back'
              onClick={() => setOpen(false)}
            >
              close
            </div>
          </div>
        </div>
      </div>
      <div
        className='chat-modal-chat-container-to-print'
        id='chat-modal-scroll-to'
      >
        <div ref={chatRef} className='chat-modal-chat-container'>
          {agents[selectedAgent].chat_history.map(({ text, role }, index) => (
            <div
              key={`${index}_container`}
              className='chat-modal-chat-output-with-buttons'
              onMouseOver={() => setHoveredMessage(index)}
              onMouseOut={() => setHoveredMessage(null)}
            >
              <div
                key={role + `_${index}`}
                className={`chat-modal-chat-${
                  role === 'human' ? 'my-' : ''
                }output-container`}
              >
                <Message
                  key={index}
                  messageRefs={messageRefs}
                  hoveredMessage={hoveredMessage}
                  selectedAgent={selectedAgent}
                  message={text}
                  author={role}
                  isGroupChat={isGroupChat}
                  index={index}
                />
              </div>
            </div>
          ))}
          {inputDisabled && (
            <div
              key='loading-message-container'
              className='chat-modal-chat-output-with-buttons'
              onMouseOver={() => {}}
              onMouseOut={() => {}}
            >
              <div
                key='loading-message'
                className='chat-modal-chat-output-container'
              >
                <Lottie animationData={typingAnimation} loop={true}></Lottie>
              </div>
            </div>
          )}
        </div>
      </div>
      <div className='chat-modal-chat-input-container'>
        <Input
          className='chat-modal-chat-input'
          placeholder={
            /* eslint-disable indent */
            inputDisabled
              ? t(
                  'chat_modal_loading_answer',
                  agents[selectedAgent].agent_name.name + ' is typing...'
                )
              : t('chat_modal_type_your_message', 'Type your message')
            /* eslint-enable indent */
          }
          variant='borderless'
          disabled={inputDisabled}
          value={inputValue}
          onChange={(e) => setInputValue(e.target.value)}
          onKeyDown={onKeyDown}
          suffix={<SendMessage disabled={inputDisabled} />}
        />
      </div>
    </div>
  );
};

export default Chat;
