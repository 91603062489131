import { Avatar } from 'antd';

const AvatarIcon = ({ isGroupChat, agents, selectedAgent, size = 38, cmp = 'header' }) => {
  if (isGroupChat) {
    if (agents.length === 2) {
      return (
        <div className={`chat-modal-${cmp}-agent-avatar-group`}>
          <Avatar className={`chat-modal-${cmp}-agent-avatar-group-top`} size={size} src={agents[0].icon}></Avatar>
          <Avatar className={`chat-modal-${cmp}-agent-avatar-group-bottom`} size={size} src={agents[1].icon}></Avatar>
        </div>
      );
    } else if (agents.length === 3) {
      return (
        <div className={`chat-modal-${cmp}-agent-avatar-group`}>
          <div className={`chat-modal-${cmp}-agent-avatar-group-top-container`}>
            <Avatar className={`chat-modal-${cmp}-agent-avatar-group-top-left`} size={size} src={agents[0].icon}></Avatar>
            <Avatar className={`chat-modal-${cmp}-agent-avatar-group-top-right`} size={size} src={agents[1].icon}></Avatar>
          </div>
          <div className={`chat-modal-${cmp}-agent-avatar-group-bottom-container`}>
            <Avatar className={`chat-modal-${cmp}-agent-avatar-group-bottom-center`} size={size} src={agents[2].icon}></Avatar>
          </div>
        </div>
      );
    }
    if (agents.length === 4) {
      return (
        <div className={`chat-modal-${cmp}-agent-avatar-group`}>
          <div className={`chat-modal-${cmp}-agent-avatar-group-top-container`}>
            <Avatar className={`chat-modal-${cmp}-agent-avatar-group-top-left`} size={size} src={agents[0].icon}></Avatar>
            <Avatar className={`chat-modal-${cmp}-agent-avatar-group-top-right`} size={size} src={agents[1].icon}></Avatar>
          </div>
          <div className={`chat-modal-${cmp}-agent-avatar-group-bottom-container`}>
            <Avatar className={`chat-modal-${cmp}-agent-avatar-group-bottom-left`} size={size} src={agents[2].icon}></Avatar>
            <Avatar className={`chat-modal-${cmp}-agent-avatar-group-bottom-right`} size={size} src={agents[3].icon}></Avatar>
          </div>
        </div>
      );
    } else {
      return (
        <div className={`chat-modal-${cmp}-agent-avatar-group`}>
          <div className={`chat-modal-${cmp}-agent-avatar-group-top-container`}>
            <Avatar className={`chat-modal-${cmp}-agent-avatar-group-top-left`} size={size} src={agents[0].icon}></Avatar>
            <Avatar className={`chat-modal-${cmp}-agent-avatar-group-top-right`} size={size} src={agents[1].icon}></Avatar>
          </div>
          <div className={`chat-modal-${cmp}-agent-avatar-group-bottom-container`}>
            <Avatar className={`chat-modal-${cmp}-agent-avatar-group-bottom-left`} size={size} src={agents[2].icon}></Avatar>
            <Avatar style={{ backgroundColor: '#f7b742' }} className={`chat-modal-${cmp}-agent-avatar-group-bottom-right`} size={size}>
              {agents.length - 3 < 10 ? agents.length - 3 : 9}+
            </Avatar>
          </div>
        </div>
      );
    }
  }
  return <Avatar className={`chat-modal-${cmp}-agent-avatar`} size={50} src={selectedAgent?.icon}></Avatar>;
};

export default AvatarIcon;
