import React from 'react';
import { Suspense, useState, useEffect } from 'react';
import {
  BrowserRouter,
  Routes,
  Route,
  useLocation,
  Navigate,
} from 'react-router-dom';
import classNames from 'classnames';
import { isMobile } from 'react-device-detect';
import { store_set, useStore } from '/src/store.js';
import Header from '/src/components/Header/Header.jsx';
import Sidebar, {
  shouldRenderSidebar,
} from '/src/components/Sidebar/Sidebar.jsx';
import { ChatModal } from '/src/components/Modal/ChatModal';

import './Router.css';

const ClassificationPage = React.lazy(() =>
  import('./ClassificationPage/ClassificationPage.jsx')
);
const Login = React.lazy(() => import('./Login/Login.jsx'));
const ForgotPassword = React.lazy(() => import('./Login/ForgotPassword.jsx'));
const ResetPassword = React.lazy(() => import('./Login/ResetPassword.jsx'));
const Dashboard = React.lazy(() => import('./Dashboard/Dashboard.jsx'));
const Users = React.lazy(() => import('./Users/Users.jsx'));
const Roles = React.lazy(() => import('./Users/TabRolesMobile.jsx'));
const SetPassword = React.lazy(() => import('./Users/SetPassword.jsx'));
const Clients = React.lazy(() => import('./Projects/TabClientsMobile.jsx'));
const ProjectsList = React.lazy(() =>
  import('./Projects/TabProjectsMobile.jsx')
);
const Boards = React.lazy(() => import('./Projects/TabBoardsMobile.jsx'));
const ResetUserPassword = React.lazy(() =>
  import('./Users/ResetUserPassword.jsx')
);

const Projects = React.lazy(() => import('./Projects/Projects.jsx'));
const Home = React.lazy(() => import('./Home/Home.jsx'));
const Agent = React.lazy(() => import('./Agents/Agent.jsx'));
const Agents = React.lazy(() => import('./Agents/Agents.jsx'));
const CreateConnector = React.lazy(() =>
  import('./CreateConnector/CreateConnector.jsx')
);
const ConnectorsList = React.lazy(() =>
  import('./ConnectorsList/ConnectorsList.jsx')
);
const TaskHistoryTable = React.lazy(() =>
  import('./TaskHistoryTable/TaskHistoryTable.jsx')
);

// Component used to store the current location in the redux store for use
// across the app.
function RouteChangeListener() {
  const location = useLocation();
  store_set('location', location);
  return <></>;
}

function ProtectedRoute({ children }) {
  // Check if the user is logged in, if not redirect to the login page
  const token = useStore('token');
  if (!token) {
    return <Navigate to='/login' replace={true} />;
  }

  // If the user is logged in, return the protected children
  return <Suspense>{children}</Suspense>;
}

function Router() {
  const agents_chat_modal_open = useStore('agents_chat_modal_open');
  const location = useStore('location');
  const [isSidebarVisible, setIsSidebarVisible] = useState(false);

  useEffect(() => {
    setIsSidebarVisible(shouldRenderSidebar(location));
  }, [location]);

  if (!isMobile) {
    return (
      <BrowserRouter basename='/'>
        <RouteChangeListener />
        <div className='main'>
          <div className='main-sidebar'>
            <Sidebar />
          </div>
          <div
            className={classNames('main-container', {
              'main-container-with-sidebar': isSidebarVisible,
            })}
          >
            <div className='main-header'>
              <Header />
            </div>
            <div className='main-content'>
              <Routes>
                <Route path='' element={<Login />} />
                <Route path='login/' element={<Login />} />
                <Route path='reset-password/' element={<ResetPassword />} />
                <Route path='forgot-password/' element={<ForgotPassword />} />
                <Route path='set-password/' element={<SetPassword />} />
                <Route
                  path='reset-user-password/'
                  element={<ResetUserPassword />}
                />
                <Route
                  path='home/'
                  element={
                    <ProtectedRoute>
                      <Home />
                    </ProtectedRoute>
                  }
                />
                <Route
                  path='dashboard/:boardId'
                  element={
                    <ProtectedRoute>
                      <Dashboard />
                    </ProtectedRoute>
                  }
                />
                <Route path='users/' element={<Users />} />
                <Route path='projects/' element={<Projects />} />
                <Route path='agent/' element={<Agent />} />
                <Route path='/agent/:hash_id' element={<Agent />} />
                <Route path='agents/' element={<Agents />} />
                <Route
                  path='classification-page/'
                  element={<ClassificationPage />}
                />
                <Route
                  path='connector/:connectorId'
                  element={
                    <ProtectedRoute>
                      <CreateConnector />
                    </ProtectedRoute>
                  }
                />
                <Route
                  path='connector/'
                  element={
                    <ProtectedRoute>
                      <CreateConnector />
                    </ProtectedRoute>
                  }
                />
                <Route
                  path='connectors/'
                  element={
                    <ProtectedRoute>
                      <ConnectorsList />
                    </ProtectedRoute>
                  }
                />
                <Route
                  path='tasks/'
                  element={
                    <ProtectedRoute>
                      <TaskHistoryTable />
                    </ProtectedRoute>
                  }
                />
              </Routes>
            </div>
          </div>
          <ChatModal setOpen={() => {}} isOpen={agents_chat_modal_open} />
        </div>
      </BrowserRouter>
    );
  }
  if (isMobile) {
    return (
      <BrowserRouter basename='/'>
        <RouteChangeListener />
        <div className='main'>
          <div className='main-container'>
            <div className='main-content'>
              <Routes>
                {/* <Route path='' element={<Login />} />
                <Route path='login/' element={<Login />} />
                <Route path='reset-password/' element={<ResetPassword />} />
                <Route path='forgot-password/' element={<ForgotPassword />} />
                <Route path='set-password/' element={<SetPassword />} /> */}
                {/* <Route
                  path='reset-user-password/'
                  element={<ResetUserPassword />}
                />
                <Route
                  path='home/'
                  element={
                    <ProtectedRoute>
                      <Home />
                    </ProtectedRoute>
                  }
                />
                <Route
                  path='dashboard/:boardId'
                  element={
                    <ProtectedRoute>
                      <Dashboard />
                    </ProtectedRoute>
                  }
                /> */}
                <Route path='users/' element={<Users />} />
                <Route path='clients/' element={<Clients />} />
                <Route path='roles/' element={<Roles />} />
                <Route path='projects/' element={<ProjectsList />} />
                <Route path='boards/' element={<Boards />} />
              </Routes>
            </div>
          </div>
          <ChatModal isOpen={agents_chat_modal_open} />
        </div>
      </BrowserRouter>
    );
  }
}

export default Router;
