import axios from 'axios';
import { store_get } from './store';
import { baseUrl } from './constants';

const axios_instance = axios.create();

axios_instance.defaults.baseURL = baseUrl;

axios_instance.interceptors.request.use(
  function (config) {
    // Do something before request is sent
    const token = store_get('token');
    config.headers.Authorization = `Bearer ${token}`;
    return config;
  },
  function (error) {
    // Do something with request error
    return Promise.reject(error);
  }
);

// Add a response interceptor
axios_instance.interceptors.response.use(
  function (response) {
    // Do something with response data
    return response;
  },
  function (error) {
    // If the error is related to the token, redirect the user to the login page
    if (
      error.response.data.status === 'error' &&
      error.response.data.reason.includes('auth_')
    ) {
      window.location.href = '/login';
    }

    return Promise.reject(error);
  }
);

export default axios_instance;
