import React from 'react';
import ReactDOM from 'react-dom/client';
import Router from './routes/Router.jsx';
import './index.css';
import store from './store.js';
import { antd_config } from './constants.js';
import { LanguageProvider } from './language_manager.jsx';
import { Provider } from 'react-redux';
import { ConfigProvider } from 'antd';
import * as Sentry from '@sentry/react';

if (window.location.hostname !== 'localhost') {
  Sentry.init({
    dsn: 'https://980cd86017e8ec32543b465be865374a@o4507297214693376.ingest.de.sentry.io/4507612880633936',
    integrations: [
      Sentry.browserTracingIntegration(),
      Sentry.replayIntegration(),
    ],
    // Performance Monitoring
    tracesSampleRate: 1.0,
    // Session Replay
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0,
  });
}

ReactDOM.createRoot(document.getElementById('root')).render(
  <React.StrictMode>
    <Provider store={store}>
      <ConfigProvider theme={antd_config}>
        <LanguageProvider>
          <Router />
        </LanguageProvider>
      </ConfigProvider>
    </Provider>
  </React.StrictMode>
);
