import * as htmlToImage from 'html-to-image';

export const HandleCaptureDiv = props => {
  const { captureDiv, isModal, setLoading, messages, isGroupChat, selectedAgent } = props; let textContent = ''; const createTextFile = ({ sender, text }) => { if (sender !== 'user') { textContent += `${sender ? sender : 'Chat'}:\n"${text}"\n\n`; } else { textContent += `User:\n"${text}"\n\n`; } }; const getSender = ({ author, selectedAgent }) => { if (!author || author === 'first_chart' || author === 'group_agent') return 'Chat'; else if (author === 'user') return 'User'; else if (isGroupChat) { return selectedAgent.agent_names[author]; } else { return selectedAgent.agent_name.name + ' ' + selectedAgent.agent_name.complement; } }; if (isGroupChat) { messages?.forEach(msg => { createTextFile({ sender: getSender({ author: msg.author, selectedAgent }), text: msg.message }); }); } else { messages?.forEach(msg => { createTextFile({ sender: getSender({ author: msg.author, selectedAgent }), text: msg.message }); }); } if (!isModal) { setLoading(true); } if (captureDiv) {
    htmlToImage.toSvg(captureDiv).then(function (dataUrl) {
      const a = document.createElement('a'); a.href = dataUrl;
      a.download = 'chat.svg';
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
      setLoading(false);
    })
      .catch(function (error) {
        console.error('Image download error', error);
      });
  } else if (textContent) {
    const blob = new Blob([textContent], { type: 'text/plain' });
    const url = URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = url;
    link.download = 'chat.txt';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    setLoading(false);
  }
};
