import { useState } from 'react';
import Snackbar from '/src/components/Snackbar/Snackbar';
import { Avatar } from 'antd';
import { HandleCaptureDiv } from '/src/utils/htmlToImg';
import ReactMarkdown from 'react-markdown';

const Message = ({
  message,
  author,
  index,
  selectedAgent,
  isGroupChat,
  hoveredMessage,
  messageRefs,
  setLoading,
}) => {
  const [open, setOpen] = useState(false);

  if (author === 'system') {
    return <></>;
  }

  if (author !== 'human') {
    return (
      <>
        {isGroupChat && (
          <Avatar
            className='chat-modal-chat-output-avatar'
            size={30}
            src={selectedAgent?.agentIcons[author]}
          />
        )}
        <div
          ref={(el) => (messageRefs.current[index] = el)}
          key={author + `_${index}`}
          style={
            author !== 'first_chart'
              ? { marginLeft: isGroupChat && '10px' }
              : { marginLeft: isGroupChat && '10px', width: '100%' }
          }
          className='chat-modal-chat-output'
        >
          {isGroupChat && author !== 'first_chart' && (
            <div className='chat-modal-chat-output-title'>
              {selectedAgent.agent_names[author] || author}
            </div>
          )}
          <ReactMarkdown>{message}</ReactMarkdown>
        </div>
        <div
          style={hoveredMessage !== index ? { zIndex: -999 } : {}}
          onClick={() =>
            HandleCaptureDiv({
              captureDiv: messageRefs.current[index],
              isModal: true,
              setLoading,
            })
          }
          className='material-icons chat-modal-chat-output-icon'
        >
          download
        </div>
        {typeof message === 'string' && (
          <div
            style={hoveredMessage !== index ? { zIndex: -999 } : {}}
            disabled={hoveredMessage !== index}
            onClick={() =>
              navigator.clipboard
                .writeText(message)
                .then(() => {
                  setOpen(true);
                })
                .catch((err) => {
                  console.error('Failed to copy text: ', err);
                })
            }
            className='material-icons chat-modal-chat-output-icon'
          >
            content_copy
          </div>
        )}
        <Snackbar open={open} text={'Copied!'} setOpen={setOpen} />
      </>
    );
  }

  return (
    <div key={author + `_${index}`} className='chat-modal-chat-my-output'>
      <ReactMarkdown>{message}</ReactMarkdown>
    </div>
  );
};

export default Message;
