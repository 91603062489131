export function getBaseUrl(aplicationName) {
  if (typeof window === 'undefined') {
    return 'http://localhost:8000/';
  }

  const currentDomain = window.location.hostname;
  if (currentDomain === 'localhost') {
    switch (aplicationName) {
      case 'backendApp':
        return 'http://localhost:8002/';
      case 'api':
        return 'http://localhost:8000/';
      default:
        return 'http://localhost:8000/';
    }
  } else {
    switch (aplicationName) {
      case 'backendApp':
        return 'https://platform.enlaight.ai/';
      case 'api':
        return 'https://api.enlaight.ai/';
      default:
        return 'https://api.enlaight.ai/';
    }
  }
}
