import { useState, useEffect, useRef } from 'react';
import { useStore, store_set } from '/src/store.js';
import { Spin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import uniqid from 'uniqid';
import { ModalToImage } from '/src/components/Modal/Modal';
import Chat from '/src/components/Modal/Chat/Chat';
import ChatSidebar from '/src/components/Modal/Chat/ChatSidebar';
import ChatAbout from '/src/components/Modal/Chat/ChatAbout';
import ChatShare from '/src/components/Modal/Chat/ChatShare';
import agents_manager from '/src/agents_manager.js';
import './ChatModal.css';

export const ChatModal = ({ isOpen }) => {
  const [selectedAgent, setSelectedAgent] = useState(false);
  const [openAbout, setOpenAbout] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const modalRef = useRef(uniqid());
  const agents = useStore('agents');
  const share_options = useStore('agents_chat_share_options');
  const share_data = useStore('agents_chat_share_data');

  const check_available_agents = () => {
    const agents_available = agents_manager.get_agents_available();
    if (agents_available.length) {
      if (!selectedAgent) {
        setSelectedAgent(agents_available[0]);
      }
      setIsLoading(false);
    }
  };

  useEffect(check_available_agents, [agents]);
  useEffect(check_available_agents, []);

  const setOpen = (open) => {
    agents_manager.set_chat_modal_open(open);
  };

  const shareDataWithAgent = (agent_hash_id) => {
    setSelectedAgent(agent_hash_id);
    agents_manager.send_message(
      agent_hash_id,
      `\nHere's some data I would like to share with you:
      \n**Chart Title:** ${share_data.title}
      \n**Chart Module:** ${share_data.module}
      \n**Chart Data:**
      \n${share_data.data}`
    );
    store_set('agents_chat_share_options', false);
    store_set('agents_chat_share_data', false);
  };

  if (!isLoading && share_options) {
    return <ChatShare shareDataWithAgent={shareDataWithAgent} />;
  }

  return (
    <ModalToImage
      open={isOpen}
      setOpen={setOpen}
      showFooter={false}
      hideClose={true}
    >
      <div key={modalRef.current} className='chat-modal-container'>
        {isLoading && (
          <div className='chat-modal-loading'>
            <Spin
              indicator={<LoadingOutlined spin style={{ fontSize: 36 }} />}
            />
          </div>
        )}
        {!isLoading && (
          <>
            <ChatSidebar
              setSelectedAgent={setSelectedAgent}
              selectedAgent={selectedAgent}
            />
            <Chat
              key={selectedAgent?.agent_id}
              selectedAgent={selectedAgent}
              setOpen={setOpen}
              setOpenAbout={setOpenAbout}
            />
            <ChatAbout
              open={openAbout}
              setOpen={setOpenAbout}
              selectedAgent={selectedAgent}
            />
          </>
        )}
      </div>
    </ModalToImage>
  );
};
