import { createSlice, configureStore, current } from '@reduxjs/toolkit';
import { useSelector } from 'react-redux';
import { version } from '/package.json';
import dayjs from 'dayjs';

const defaultStartDate = dayjs().subtract(1, 'month');
const defaultEndDate = dayjs();

// This is the initial store state
const initial_state = {
  token: false,
  value: 0,
  location: {},
  navigate_loading: false,
  first_name: '',
  last_name: '',
  email: '',
  permissions: [],
  boardFilters: [],
  date: {
    start_date: defaultStartDate.format('YYYY-MM-DDT00:00:00'),
    end_date: defaultEndDate.format('YYYY-MM-DDT00:00:00'),
  },
  refreshFilters: false,
  translations: {},
  agents: {},
  agents_chat_modal_open: false,
  agents_chat_share_options: false,
  agents_chat_share_data: false,
  translation_in_progress: false,
  version,
};

// Check the version of the persisted store, if it's different from the current
// application, then reset the store and the localstorage in general
let local_storage_version = localStorage.getItem('version');
if (local_storage_version !== version) {
  localStorage.clear();
}

// Check if there is a persisted store initial state in localstorage
let persisted_store = localStorage.getItem('store');
if (persisted_store) {
  persisted_store = JSON.parse(persisted_store);
} else {
  localStorage.setItem('store', JSON.stringify(initial_state));
  persisted_store = initial_state;
  // Also set the version in the localstorage to the current version
  localStorage.setItem('version', version);
}

// Create the only reducer we will need
const main_slice = createSlice({
  name: 'main',
  initialState: persisted_store,
  reducers: {
    main_reducer: (state, params) => {
      if (params.payload.key === 'full_store_reset') {
        state = initial_state;
        localStorage.clear();
        return;
      }

      // Update the state
      state[params.payload.key] = params.payload.value;

      // Update the localstorage with the latest state
      // Important to create persistence
      // But do so asynchronously so as not to block the UI
      const update_localstorage = new Promise(function (resolve, reject) {
        try {
          localStorage.setItem('store', JSON.stringify(current(state)));
          resolve();
        } catch (error) {
          reject();
        }
      });
      update_localstorage.then(() => {});
    },
  },
});
const { main_reducer } = main_slice.actions;

// Create the store
const store = configureStore({
  reducer: main_slice.reducer,
});
export default store;

// Export the funcions we will need to manipulate the store

// store_set sets a value in the store given its key
export const store_set = (key, value) => {
  store.dispatch(main_reducer({ key, value }));
};

// store_get retrieves a value from the store given its key
export const store_get = (key) => {
  const current_state = store.getState();
  return current_state[key];
};

// useStore subscribes a component to a value in the store given
// its key - to be used as a react hook
export const useStore = (key) => {
  return useSelector((state) => state[key]);
};
