import { useState, useEffect } from 'react';
import { useStore } from '/src/store.js';
import agents_manager from '/src/agents_manager.js';
import { Modal, Avatar } from 'antd';
import uniqid from 'uniqid';
import './ChatShare.css';

const ChatShare = ({ shareDataWithAgent }) => {
  const agents = useStore('agents');
  const share_options = useStore('agents_chat_share_options');
  const [agentsEnabled, setAgentsEnabled] = useState([]);

  const check_available_agents = () => {
    const agents_available = agents_manager.get_agents_available();
    const common_agents = agents_available.filter((agent_id) =>
      share_options.includes(agent_id)
    );
    setAgentsEnabled(common_agents);
  };

  useEffect(check_available_agents, [agents, share_options]);
  useEffect(check_available_agents, []);

  return (
    <Modal
      closeable
      open
      footer={null}
      centered
      onCancel={agents_manager.close_chat_share_modal}
      title={'Share with:'}
    >
      <div className='chat-share'>
        {agentsEnabled.map((agent_hash_id) => (
          <div
            key={uniqid()}
            className='chat-share-list-item'
            onClick={() => shareDataWithAgent(agent_hash_id)}
          >
            <Avatar
              className='chat-share-list-avatar'
              size={44}
              src={agents[agent_hash_id].icon}
            ></Avatar>
            <div>
              <div className='chat-share-list-title'>
                {agents[agent_hash_id].agent_name.name}
              </div>
              <div className='chat-share-list-subtitle'>
                {agents[agent_hash_id].agent_name.complement}
              </div>
            </div>
          </div>
        ))}
      </div>
    </Modal>
  );
};

export default ChatShare;
