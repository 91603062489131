import React, { useEffect, useState } from 'react';
import './LanguagePicker.css';
import { Popover } from 'antd';
import Flag from 'react-world-flags';
import globeIcon from '../../ui/globe.svg';
import event_bus from '../../../../backendApp/src/event_bus.js';

function LanguagePicker() {
  const [selectedLanguage, setSelectedLanguage] = useState('EN (US)');
  const [flagCode, setFlagCode] = useState('gb');
  const [flagHeight, setFlagHeight] = useState('12');
  const [isPopoverOpen, setIsPopoverOpen] = useState(false);

  function handleLanguageChange(countryCode, language, height) {
    setFlagHeight(height);
    setSelectedLanguage(language);
    setFlagCode(countryCode);
    setIsPopoverOpen(false);
    event_bus.emit('language_change', countryCode);
  }

  const LanguagePopover = () => {
    return (
      <div className='language-picker-popover'>
        <a onClick={() => handleLanguageChange('gb', 'EN (US)', 14)}>
          <div
            className={`language-picker-option ${
              selectedLanguage === 'EN (US)' ? 'selected' : ''
            }`}
          >
            <span className='language-picker-option-text'>English</span>
            <Flag height='12' code='gb' />
          </div>
        </a>
        <a onClick={() => handleLanguageChange('es', 'ES (EU)', 14)}>
          <div
            className={`language-picker-option ${
              selectedLanguage === 'ES (EU)' ? 'selected' : ''
            }`}
          >
            <span className='language-picker-option-text'>Español</span>
            <Flag height='15' code='es' />
          </div>
        </a>
        <a onClick={() => handleLanguageChange('pt', 'PT (PT)', 14)}>
          <div
            className={`language-picker-option ${
              selectedLanguage === 'PT (PT)' ? 'selected' : ''
            }`}
          >
            <span className='language-picker-option-text'>Português</span>
            <Flag height='15' code='pt' />
          </div>
        </a>
        <a onClick={() => handleLanguageChange('kk', 'KZ (KZ)', 14)}>
          <div
            className={`language-picker-option ${
              selectedLanguage === 'KZ (KZ)' ? 'selected' : ''
            }`}
          >
            <span className='language-picker-option-text'> қазақ тілі</span>
            <Flag height='12' code='kz' />
          </div>
        </a>
        <a onClick={() => handleLanguageChange('ru', 'RU (RU)', 14)}>
          <div
            className={`language-picker-option ${
              selectedLanguage === 'RU (RU)' ? 'selected' : ''
            }`}
          >
            <span className='language-picker-option-text'>Русский</span>
            <Flag height='12' code='ru' />
          </div>
        </a>
        <a onClick={() => handleLanguageChange('uz', 'UZ (UZ)', 14)}>
          <div
            className={`language-picker-option ${
              selectedLanguage === 'UZ (UZ)' ? 'selected' : ''
            }`}
          >
            <span className='language-picker-option-text'>Oʻzbek</span>
            <Flag height='12' code='uz' />
          </div>
        </a>
      </div>
    );
  };

  return (
    <div className={`language-picker ${isPopoverOpen ? 'popover-open' : ''}`}>
      <Popover
        placement='bottomRight'
        content={<LanguagePopover />}
        trigger='click'
        visible={isPopoverOpen}
        onVisibleChange={(visible) => setIsPopoverOpen(visible)}
      >
        <div
          className={`language-picker-selected ${
            isPopoverOpen ? 'active' : ''
          }`}
        >
          <span className={`language-picker-selected-text`}>
            {selectedLanguage}
          </span>
          <img src={globeIcon} alt='globe' height={20} className='globe-icon' />
        </div>
      </Popover>
    </div>
  );
}

export default LanguagePicker;
