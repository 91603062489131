import { store_set, store_get } from '/src/store.js';
import axios_instance from '/src/request_manager.js';
import event_bus from '/src/event_bus.js';

// Possible agent states
const AGENT_STATES = {
  // Agent is first initiating
  LOADING: 'loading',
  // Agent is idle and ready to answer
  IDLE: 'idle',
  // Agent is responding to a message
  RESPONDING: 'responding',
  // Agent failed to load and is not ready
  ERROR: 'error',
};

class AgentsManager {
  // Initialize the agent in the store and get the agent's information from the API
  init_agent(agent_hash_id) {
    // Deep copy the current agents from the store
    let current_agents = JSON.parse(JSON.stringify(store_get('agents')));

    if (!(agent_hash_id in current_agents)) {
      current_agents[agent_hash_id] = {
        agent_id: agent_hash_id,
        state: AGENT_STATES.LOADING,
        chat_history: [],
      };
      store_set('agents', current_agents);
      this.touch_agent(agent_hash_id);
    }
  }

  open_chat_modal() {
    store_set('agents_chat_modal_open', true);
  }

  close_chat_modal() {
    store_set('agents_chat_modal_open', false);
  }

  set_chat_modal_open(open) {
    store_set('agents_chat_modal_open', open);
  }

  open_chat_share_modal(share_options, share_data) {
    store_set('agents_chat_share_options', share_options);
    store_set('agents_chat_share_data', share_data);
    store_set('agents_chat_modal_open', true);
  }

  close_chat_share_modal() {
    store_set('agents_chat_share_options', false);
    store_set('agents_chat_share_data', false);
    store_set('agents_chat_modal_open', false);
  }

  // Get the agents that are available to chat
  get_agents_available() {
    // Deep copy the current agents from the store
    let current_agents = JSON.parse(JSON.stringify(store_get('agents')));

    return Object.keys(current_agents).reduce((acc, agent_hash_id) => {
      if (
        current_agents[agent_hash_id].state === AGENT_STATES.IDLE ||
        current_agents[agent_hash_id].state === AGENT_STATES.RESPONDING
      ) {
        acc.push(agent_hash_id);
      }
      return acc;
    }, []);
  }

  // Send a message to the agent
  send_message(agent_hash_id, message) {
    let current_agents = JSON.parse(JSON.stringify(store_get('agents')));
    current_agents[agent_hash_id].state = AGENT_STATES.RESPONDING;
    current_agents[agent_hash_id].chat_history.push({
      role: 'human',
      text: message,
    });
    store_set('agents', current_agents);
    this.touch_agent(agent_hash_id);
    event_bus.emit('agent_message_update', agent_hash_id);
  }

  // Clear the agent's chat history
  clear_agent(agent_hash_id) {
    let current_agents = JSON.parse(JSON.stringify(store_get('agents')));
    current_agents[agent_hash_id].chat_history = [];
    store_set('agents', current_agents);
    this.touch_agent(agent_hash_id);
  }

  touch_agent(agent_hash_id) {
    // Deep copy the current agents from the store
    let current_agents = JSON.parse(JSON.stringify(store_get('agents')));

    axios_instance
      .post('agent/', {
        agent_hash_id: agent_hash_id,
        chat_history: JSON.stringify(
          current_agents[agent_hash_id].chat_history
        ),
      })
      .then((response) => {
        // If we don't get a success status, then we display an error message to the user
        if (response.data.status !== 'success') {
          return;
        }

        // If we get a success status, then we set the config object
        if (response.data.status === 'success') {
          // Deep copy the current agents from the store
          let current_agents = JSON.parse(JSON.stringify(store_get('agents')));
          current_agents[agent_hash_id].state = AGENT_STATES.IDLE;
          current_agents[agent_hash_id].agent_name = response.data.agent_name;
          current_agents[agent_hash_id].icon = response.data.icon;
          current_agents[agent_hash_id].description = response.data.description;
          current_agents[agent_hash_id].chat_history =
            response.data.chat_history;
          store_set('agents', current_agents);
          event_bus.emit('agent_message_update', agent_hash_id);
          return;
        }
      });
  }
}

const agents_manager = new AgentsManager();

export default agents_manager;
