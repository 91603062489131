import { useEffect } from 'react';
import { message } from 'antd';

const Snackbar = ({ text, open, setOpen }) => {
  const [messageApi, contextHolder] = message.useMessage();
  useEffect(() => {
    if (open) {
      messageApi.info(text);
      setTimeout(() => {
        setOpen(false);
      }, 3000);
    }
  }, [open]);
  return <>{contextHolder}</>;
};

export default Snackbar;
