import { Children } from 'react';
import { Button, Modal } from 'antd';
import * as htmlToImage from 'html-to-image';
import './Modal.css';

// This component is used to create a copy of the modal with a new div with padding in order to download the modal image with better visualization
export const ModalToImage = ({
  isCopy,
  open,
  setOpen,
  cardId,
  setLoading,
  showFooter = true,
  hideClose = false,
  children,
}) => {
  const HandleCaptureDiv = (captureDiv, isModal) => {
    if (!isModal) {
      setLoading(true);
    }

    if (captureDiv) {
      htmlToImage
        .toPng(captureDiv)
        .then(function (dataUrl) {
          const a = document.createElement('a');
          a.href = dataUrl;
          a.download = 'my-node.png';
          document.body.appendChild(a);
          a.click();
          document.body.removeChild(a);
          setLoading(false);
        })
        .catch(function (error) {
          console.error('Image download error', error);
        });
    }
  };

  return (
    <Modal
      className={
        hideClose
          ? 'modal-container modal-container-without-close'
          : 'modal-container'
      }
      position={isCopy ? 'absolute' : null}
      top={isCopy ? '100000px' : '0'}
      open={open}
      onOk={() => setOpen(false)}
      onCancel={() => setOpen(false)}
      footer={[]}
    >
      {isCopy && (
        <div
          id={cardId.current + 'modal'}
          style={{
            padding: '10px',
            backgroundColor: '#ffffff',
          }}
        >
          {Children.map(children, (child) => (
            <> {child} </>
          ))}
        </div>
      )}
      {!isCopy && (
        <>
          {Children.map(children, (child) => (
            <> {child} </>
          ))}
        </>
      )}
      <div
        style={{
          display: showFooter ? 'flex' : 'none',
          justifyContent: 'right',
        }}
      >
        <Button
          onClick={() =>
            HandleCaptureDiv(
              document.getElementById(cardId.current + 'modal'),
              true
            )
          }
          className='material-icons dashboard-card-title-icon'
          style={{
            color: 'black',
            width: '55px',
            height: '23',
          }}
        >
          download
        </Button>
        <Button key='back' onClick={() => setOpen(false)}>
          Close
        </Button>
      </div>
    </Modal>
  );
};
