import { getBaseUrl } from './common';

export const antd_config = {
  token: {
    colorPrimary: '#F7B742',
  },
};

// App URL
export const baseUrl = getBaseUrl('api');
