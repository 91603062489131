import { useTranslate } from '@tolgee/react';
import { Avatar } from 'antd';
import { useStore } from '/src/store.js';

const About = ({ open, setOpen, selectedAgent }) => {
  const agents = useStore('agents');
  const { t } = useTranslate();
  if (open)
    return (
      <div className='chat-modal-about-container'>
        <div className='chat-modal-header'>
          <div className='chat-modal-header-title'>
            {t('chat_modal_about', 'About')}
            <div
              style={{
                display: 'flex',
                justifyContent: 'right',
              }}
            >
              <div
                className='material-icons chat-modal-header-title-icon'
                key='back'
                onClick={() => setOpen(false)}
              >
                close
              </div>
            </div>
          </div>
        </div>
        <div className='chat-modal-about-card'>
          <Avatar
            className='chat-modal-header-agent-avatar'
            size={120}
            src={agents[selectedAgent].icon}
          ></Avatar>
          <div className='chat-modal-about-card-title'>
            {agents[selectedAgent].agent_name.name +
              ' ' +
              agents[selectedAgent].agent_name.complement}
          </div>
          <div className='chat-modal-about-card-subtitle'>
            {agents[selectedAgent].description}
          </div>
        </div>
      </div>
    );
};

export default About;
