import { Tolgee, TolgeeProvider } from '@tolgee/react';
import { useRef } from 'react';
import { useTolgee } from '@tolgee/react';
import event_bus from '/src/event_bus.js';
import Flag from 'react-world-flags';
import { debounce } from 'throttle-debounce';
import { useTranslate } from '@tolgee/react';
import axios_instance from '/src/request_manager.js';
import { useStore, store_set, store_get } from '/src/store.js';
import * as language_en from './languages/en.json';
import * as language_es from './languages/es.json';
import * as language_pt from './languages/pt.json';
import * as language_kk from './languages/kk.json';
import * as language_ru from './languages/ru.json';
import * as language_uz from './languages/uz.json';
import { useEffect } from 'react';

const languages = [
  {
    code: 'en',
    translations: language_en,
  },
  {
    code: 'es',
    translations: language_es,
  },
  {
    code: 'pt',
    translations: language_pt,
  },
  {
    code: 'kk',
    translations: language_kk,
  },
  {
    code: 'ru',
    translations: language_ru,
  },
  {
    code: 'uz',
    translations: language_uz,
  },
];

let stored_language = localStorage.getItem('language');
if (!stored_language) {
  stored_language = 'en';
  localStorage.setItem('language', stored_language);
}

const tolgee = Tolgee().init({
  language: stored_language,
  staticData: {
    en: language_en,
    es: language_es,
    pt: language_pt,
    kk: language_kk,
    ru: language_ru,
    uz: language_uz,
  },
});

export default tolgee;

export function getAvailableLanguages(height = 16) {
  return [
    { code: 'en', name: 'English', flag: <Flag code='GB' height={height} /> },
    { code: 'es', name: 'Español', flag: <Flag code='ES' height={height} /> },
    { code: 'pt', name: 'Português', flag: <Flag code='PT' height={height} /> },
    { code: 'kk', name: 'Қазақша', flag: <Flag code='KZ' height={height} /> },
    { code: 'ru', name: 'Русский', flag: <Flag code='RU' height={height} /> },
    { code: 'uz', name: 'O\'zbek', flag: <Flag code='UZ' height={height} /> },
  ];
}

const active_translations = [];

const translations_interval = setInterval(() => {
  const translations = store_get('translations');
  let all_translations_ready = true;
  active_translations.map((translation) => {
    if (
      !(
        translations &&
        translations[translation.language] &&
        translations[translation.language][translation.original_text]
      )
    ) {
      all_translations_ready = false;
    }
  });
  const translation_in_progress = store_get('translation_in_progress');
  if (translation_in_progress !== !all_translations_ready) {
    store_set('translation_in_progress', !all_translations_ready);
    clearInterval(translations_interval);
  }
}, 1000);

function LanguageController() {
  const tolgee = useTolgee(['language']);
  const translation_in_progress = useStore('translation_in_progress');
  const location = useStore('location');

  useEffect(() => {
    if (translation_in_progress) {
      event_bus.emit('loadingTranslation');
    } else {
      event_bus.emit('finishedTranslation');
    }
  }, [translation_in_progress, location]);

  // Check if event already has listeners before calling the emitter,
  // this will avoid one being added at each rendering and prevent memory leaks
  if (!('language_change' in event_bus._events)) {
    event_bus.on('language_change', (country_or_language_code) => {

      switch (country_or_language_code) {
      // Spanish
      case 'es':
        tolgee.changeLanguage('es');
        localStorage.setItem('language', 'es');
        break;
  
      // Portuguese
      case 'pt':
        tolgee.changeLanguage('pt');
        localStorage.setItem('language', 'pt');
        break;
      case 'br':
        tolgee.changeLanguage('pt');
        localStorage.setItem('language', 'pt');
        break;
  
      // English
      case 'en':
        tolgee.changeLanguage('en');
        localStorage.setItem('language', 'en');
        break;
      case 'gb':
        tolgee.changeLanguage('en');
        localStorage.setItem('language', 'en');
        break;
      case 'us':
        tolgee.changeLanguage('en');
        localStorage.setItem('language', 'en');
        break;
  
      // Kazakh
      case 'kk':
        tolgee.changeLanguage('kk');
        localStorage.setItem('language', 'kk');
        break;
  
      // Russian
      case 'ru':
        tolgee.changeLanguage('ru');
        localStorage.setItem('language', 'ru');
        break;
  
      // Uzbek
      case 'uz':
        tolgee.changeLanguage('uz');
        localStorage.setItem('language', 'uz');
        break;
  
      default:
        break;
      }
  
    });
  }
}

// TO-DO add loading fallback as a fallback={<component />} attribute
export function LanguageProvider({ children }) {
  return (
    <TolgeeProvider tolgee={tolgee}>
      <LanguageController />
      {children}
    </TolgeeProvider>
  );
}

// Function to request translations from the backend - used only for strings
// that are not in the static translations files
// Translations are stored in the redux store and thus in the localstorage
const requestTranslation = (source, lang) => {
  const pending_translation_set = new Set(source);
  const pending_translation = Array.from(pending_translation_set);

  axios_instance
    .get('translate/', {
      params: {
        source: JSON.stringify(pending_translation),
        lang: lang,
      },
    })
    .then((response) => {
      if (response.data.status === 'success') {
        const current_translations = store_get('translations');

        const new_translations_for_lang = {
          ...(current_translations[lang] ? current_translations[lang] : {}),
          ...response.data.translations,
        };

        const new_translations = {
          ...current_translations,
          [lang]: new_translations_for_lang,
        };
        store_set('translations', new_translations);
      }
    });
};

const debouncedRequestTranslation = debounce(1000, requestTranslation, {
  atBegin: false,
});

// Try to translate a string from the original string instead of a key
// Try using the static translations files first and, if not found,
// schedule the translation to be requested from the backend
export function useReverseTranslate() {
  const translations = useStore('translations');
  const pendingTranslations = useRef([]);
  const tolgee = useTolgee(['language']);
  const { t } = useTranslate();
  const current_language = tolgee.getLanguage();

  // Look for the translation in the static translations files
  const rt = (original_text, default_text) => {
    // If the string is empty, simply return it
    if (original_text === '') {
      return '';
    }

    let original_key = false;
    languages.map((lang_obj) => {
      Object.keys(lang_obj.translations).map((key) => {
        if (lang_obj.translations[key] === original_text) {
          original_key = key;
        }
      });
    });

    // If the translation is found in the static translations files, return it
    if (original_key) {
      return t(original_key, default_text);
    }

    // If the translation is not found in the static translations files, try to look
    // for machine translations stored in the redux store
    if (
      translations &&
      translations[current_language] &&
      translations[current_language][original_text]
    ) {
      return translations[current_language][original_text];
    }

    // If nothing is found, then schedule the translation to be requested from the backend
    pendingTranslations.current.push(original_text);
    active_translations.push({
      language: current_language,
      original_text: original_text,
    });
    debouncedRequestTranslation(pendingTranslations.current, current_language);
    return default_text;
  };
  return { rt };
}
